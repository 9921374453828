import { Row } from "react-bootstrap";
import affiche_zomerbar from  "./images/affiche_zomerbar.jpg"
import affiche_workshop from "./images/workshop.png"
import frame from "./images/frame.png"


export function Header() {
    return (
        <header id='header'>
            <div className='intro'>
                <div className='overlay  py-5'>
                    <div className='container my-5 py-5'>
                        <div className='row'>
                            <div className="col-12 d-flex justify-content-center flex-column align-items-center my-5 pb-5">
                                <div className="d-flex justify-content-center flex-column align-items-center">
                                    <Row>
                                        
                                    </Row>
                                    <Row>
                                        <img
                                            src={affiche_workshop}
                                            style={{ height: "80rem", width: "60rem" }}
                                            className="d-inline-block align-top mb-2 border rounded"
                                            alt=""
                                        >
                                        </img>
                                    </Row>
                                </div>
                                <div className="mouse_scroll mt-5">

                                    <div className="mouse">
                                        <div className="wheel"></div>
                                    </div>
                                    <div>
                                        <span className="m_scroll_arrows unu"></span>
                                        <span className="m_scroll_arrows doi"></span>
                                        <span className="m_scroll_arrows trei"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}